'use strict';

Gri.module({
  name: 'image-display-alpha',
  ieVersion: null,
  $el: $('.image-display-alpha'),
  container: '.image-display-alpha',
  fn: function () {

    var _lastVal = 1;

    const $this = this.$el;
    let $range = $this.find('[type="range"]');
    let init = false;
    let _max = $range.attr('max');
    let option = {
      polyfill : false,
      min: "1",
      max: _max,
      value: "1",
      onSlide: function (position, value) {
          if(_lastVal != value) {
            $(".device:not([data-current="+ value +"])").removeClass('active');
            $(".device[data-current="+ value +"]").addClass('active');
          } else if (value == _max && !$(".device[data-current='"+_max+"']").hasClass('active')) {
            console.log('here')
            $(".device[data-current='"+_max+"']").addClass('active');
          }

          _lastVal = value;
      },
      onInit: function () {
        init = true;
        let $rangeHandle = $('.rangeslider__handle');
        $rangeHandle.append('<div class="icon-wrapper">' +
          '<i class="icon-arrow-left"></i>' +
          '<i class="icon-arrow-left"></i>' +
          '<i class="icon-arrow-left"></i>' +
          '</div>');
          $(".device").eq(39).addClass('active');
          $('input[type="range"]').rangeslider().val(40).change();
          var img = new Image();
              img.onload = function() {
                var height = this.height;
                if($(window).width() < 768) {
                  $(".image-display-alpha-content").height(height);
                  $(".group-icons-alpha").height("auto");

                } else {
                  $(".image-display-alpha-content, .group-icons-alpha").height(height);
                }
              }

              var url = $(".device").eq(0).attr('style')
                  url = url.replace("background-image: url('", '');
                  url = url.replace("')", '');

              img.src = url;
      }
    };

    function rangeTypeController() {
      let windowW = $(window).width();
      if(windowW < 768) {
        $range.attr('data-orientation', 'horizontal');
        $('.rangeslider-wrapper').removeClass('vertical').addClass('horizontal');
        $range.rangeslider('destroy');
        $range.rangeslider(option);
      } else {
        $range.attr('data-orientation', 'vertical');
        $('.rangeslider-wrapper').removeClass('horizontal').addClass('vertical');
        $range.rangeslider('destroy');
        $range.rangeslider(option);
      }
    }

    $range.rangeslider(option);

    // region events
    $(window).on('load', function () {
      if(init) {
        rangeTypeController();
        $(window).on('resize', rangeTypeController);
      }
    });
  }
});
